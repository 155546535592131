// Libraries
import qs from 'qs'

// Shared
import { History } from 'shared/utils/Routing'
import moment from 'shared/lib/moment'
import EventTracker from 'back_office/v2/utils/event_tracker'
import { pluralize } from 'inflected'

const order = (order, action) => {
  return _resource('orders', order, action)
}

const customer = (customer, action = 'edit') => {
  return _resource('customers', customer, action)
}

const product = (product, action) => {
  return _resource('product_groups', product, action)
}

const bundle = (bundle, action) => {
  return _resource('bundles', bundle, action)
}

const customers = (action = undefined) => {
  return _collection('customers', action)
}

const documents = (action = undefined) => {
  return _collection('reports/documents', action)
}

const orders = (action = undefined) => {
  return _collection('orders', action)
}

const bundles = (action = undefined) => {
  return _collection('bundles', action)
}

const products = (action = undefined) => {
  return _collection('product_groups', action)
}

const document = (document, action) => {
  return _resource('documents', document, action)
}

const collection = (collection, action) => {
  return _resource('collections', collection, action)
}

const collections = (action = undefined) => {
  return _resource('collections', action)
}

const location = (location, action) => {
  return _resource('locations', location, action)
}

const calendarLink = (action = 'products', options = {}) => {
  const formatDate = (date) => moment(date).format()

  if (options.from) options.from = formatDate(options.from)
  if (options.till) options.till = formatDate(options.till)

  const queryString = qs.stringify(options, { encode: false })

  return `/calendar/${action}?${queryString}`
}

const navigateToResource = (type, object, action) => {
  return navigate(eval(type)(object, action))
}

// Navigates to given path. When an event is given it will prevent the default
// and when CTRL or CMD or Middle Mouse key is pressed it won't do anything.
//
const navigate = (fullPath, e) => {
  if (e && (e.ctrlKey || e.metaKey || (e.button === 1))) {
    // CTRL or CMD or Middle Mouse is pressed to open in a new window
    return false
  }

  e && e.preventDefault()

  if (checkForUnsavedChanges()) {
    History.push(fullPath)
  }

  if (window.localStorage.getItem('settingsBackLink')) {
    const data = JSON.parse(window.localStorage.getItem('settingsBackLink'))

    // data.target
    // - is the target of the settingsBackLink
    // data.resource (optional)
    // - is the resource path where the settingsBackLink should be visible (example: 'tax_categories')
    // If the fullPath is not the target, and the fullPath does not starts with the resource path the user
    // navigates to a page which should not show the settingsBackLink
    if (data.target !== fullPath && !fullPath.startsWith(`/${data.resource}`)) {
      localStorage.removeItem('settingsBackLink')
    }
  }
}

// Navigates directly to link.
// Add data attribute if navigation should be Event tracked
//
// @example
//   <a href="some-link" onClick={Utils.Routing.navigateLink}>some link</a>
// @example of tracking
//   <a href="some-link" onClick={Utils.Routing.navigateLink} data-track-event='Dashboard link clicked'>some link</a>
//
const navigateLink = (e) => {
  const element = e.currentTarget
  const trackingEvent = element.getAttribute('data-track-event')
  const external = element.target === '_blank'

  if (trackingEvent) {
    const hrefURL = new URL(element.href)
    const trackingHref = external ? element.href : `${hrefURL.pathname}${hrefURL.search}`

    EventTracker.track(trackingEvent, { href: trackingHref })
  }

  if (!external) {
    let link = element.href

    link = link.replace(window.location.origin, '')

    return RoutingUtils.navigate(link, e)
  }
}

const hasUnsavedChanges = () => {
  if (!window.testMode && window.dirtyForms && !window.skipUnsavedChangesCheck) {
    // Check if there are any dirty forms in dirtyForms
    return Object.values(window.dirtyForms).filter((value) => value)[0]
  } else {
    return false
  }
}

const checkForUnsavedChanges = () => {
  if (hasUnsavedChanges()) {
    return window.confirm('Leave with unsaved changes?')
  } else {
    return true
  }
}

const resourcePath = (resource, id, ...paths) => {
  return _path(pluralize(resource), id, ...paths)
}

const _resource = (path, object, action) => {
  return _path(path, (object != null ? object.id : undefined) || object, action)
}

const _collection = (path, action) => {
  return _path(path, action)
}

const _path = (...paths) => {
  const path = (paths.filter((p) => (p != null))).join('/')

  return `/${path}`
}

const RoutingUtils = {
  order,
  customer,
  product,
  bundle,
  customers,
  documents,
  orders,
  bundles,
  products,
  document,
  collection,
  collections,
  location,
  calendarLink,
  navigateToResource,
  navigate,
  navigateLink,
  hasUnsavedChanges,
  checkForUnsavedChanges,
  resourcePath,
  _resource,
  _collection,
  _path
}

export default RoutingUtils
