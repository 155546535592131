import _some from 'lodash/some'
import _every from 'lodash/every'

import Format from 'shared/utils/format'
import Time from 'shared/utils/time'
import Routing from './routing_utils'
import Settings from 'shared/utils/settings'
import setPageTitle from 'shared/utils/setPageTitle'
import mergeDefaultRequestOptions from 'shared/utils/mergeDefaultRequestOptions'
import EventTracker from './event_tracker'

export { default as Format } from 'shared/utils/format'
export { default as Time } from 'shared/utils/time'
export { default as Routing } from './routing_utils'
export { default as Settings } from 'shared/utils/settings'
export { default as EventTracker } from './event_tracker'
export { featureEnabled, featureDisabled, featureAllowed, FeatureFlags } from 'shared/utils/features'

export { default as setPageTitle } from 'shared/utils/setPageTitle'
export { default as mergeDefaultRequestOptions } from 'shared/utils/mergeDefaultRequestOptions'

const getPermissions = () => {
  const state = window.store.getState()
  const employeeId = state.session.currentEmployeeId
  const employee = state.orm.employees.getById(employeeId)

  return employee.permissions || []
}

export const hasPermission = (...permissions) => {
  const employeePermissions = getPermissions()

  return _some(permissions, (permission) => {
    return employeePermissions.includes(permission)
  })
}

export const hasAllPermissions = (permissions) => {
  const employeePermissions = getPermissions()

  return _every(permissions, (permission) => {
    return employeePermissions.includes(permission)
  })
}

export const URLRegexp =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/
export const PhoneRegexp = /tel:[+\-\d]*/

export const stringIsLink = (string) => {
  return string.match(URLRegexp) || string.match(PhoneRegexp)
}

export const prependProtocol = (string) => {
  return string.replace(URLRegexp, (match) => {
    if (!match.startsWith('http') && !match.startsWith('https') && !match.startsWith('mailto')) {
      return `https://${match}`
    }

    return match
  })
}

export const generateUUID = () => {
  try {
    return crypto.randomUUID()
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    // Fallback for older browsers and non-secure contexts
    return window.URL.createObjectURL(new Blob([])).substring(34)
  }
}

export default {
  hasPermission,
  setPageTitle,
  mergeDefaultRequestOptions,

  Format,
  Time,
  Routing,
  Settings,
  EventTracker
}
